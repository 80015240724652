<template>
	<div class="setmeal">
		<NavBar
			v-if="isWeixin_status == false"
			fixed
			left-arrow
			@click-left="goBack"
			title="礼品卡"
		></NavBar>
		<img src="@static/home/giftcard.jpg" alt="" />
	</div>
</template>
<script>
import { NavBar } from "vant";
export default {
	components: {
		NavBar
	},
	mounted() {
		this.$dialog
			.alert({
				message:
					"此功能正在内测阶段，礼品卡表示用户可以自己制作相应的加油礼品卡赠送给自己的亲朋好友"
			})
			.then(() => {
				this.$router.go(-1);
			});
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		}
	}
};
</script>
<style lang="scss" scoped>
.setmeal {
	width: 100%;
	height: 100vh;
	padding-top: 50px;
	background: rgba(244, 244, 244, 1);
	overflow-y: scroll;
	overflow-x: hidden;
	// overflow: hidden;
	img {
		width: 100%;
	}
}
</style>
